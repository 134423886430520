import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Curs Online d'Orientació a l'ISPC 38ª Promoció (2024-2025)</Title>
                <Text>
                    T'agradaria tenir tots els continguts de la 38a promoció ja?
                    <br />
                    <br />
                    Et presentem el millor curs per l'accés a la 38a promoció.
                    <br />
                    <br />
                    Si ets aspirant de la 38a promoció aquest curs és per tu, podràs avançar-te i
                    tenir tots els continguts actualitzats en un únic campus.
                    <br />
                    <br />
                    Un curs amb tot el contingut teòric i actualitzat.
                    <br />
                    <br />
                    Ja t'avancem que hi ha moltíssim material.
                    <br />
                    <br />
                    Al curs trobaràs:
                    <br />
                    <br />
                    -Totes les Unitats Formatives separades i organitzades.
                    <br />
                    <br />
                    -Resums de cada unitat
                    <br />
                    <br />
                    -Treballs en xarxa
                    <br />
                    <br />
                    -Exàmens oficials de la 37a i anteriors
                    <br />
                    <br />
                    El pòdcast de les unitats formatives més rellevants.
                    <br />
                    <br />
                    -Material de suport.
                    <br />
                    <br />
                    Des del primer moment que et matricules al curs, tens accés a tots els
                    continguts fins en finalitzar l'ISPC.
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/9tsxxdtHn8g"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
